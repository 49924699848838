:root {
  --bg-color: transparent;
  --width: 460px;
  --small-width: 300px;
  --stroke-opacity: 0.5;
  --stroke-color: #131414;
  --small-stroke-color: #131414;
  --rotation: -1turn;
  --small-rotation: 1turn;
  --animation-time: 20s;
  --small-animation-time: 6s;
  --hover-animation-time: 30s;
}

@media screen and (max-width: 361px) {
  :root {
    --width: 280px;
    --small-width: 180px;
  }
}

@media screen and (max-width: 415px) {
  :root {
    --width: 300px;
    --small-width: 200px;
  }
}

@media screen and (max-width: 640px) {
  :root {
    --width: 330px;
    --small-width: 250px;
  }
}

.circle-animated {
  position: absolute;
  top: calc(50% - var(--width) / 2);
  left: calc(50% - var(--width) / 2);
  width: var(--width);
  overflow: visible;
  border-radius: 50%;
  background: var(--bg-color);
  transition: all 0.2s ease;
}

.circle-small {
  --width: var(--small-width);
  --rotation: var(--small-rotation);
  --stroke-color: var(--small-stroke-color);
  --animation-time: var(--small-animation-time);
}

#circlePathBig {
  color: rgb(245, 120, 97);
  opacity: var(--stroke-opacity, 0);
  transition: all 0.2s ease;
}

#circlePath {
  opacity: var(--stroke-opacity, 0);
  transition: all 0.2s ease;
  color: #7c9d96;
}

.circle-small:hover {
  --stroke-opacity: 1;
  --animation-time: var(--hover-animation-time);
  box-shadow: 0px 0px 20px 0px var(--small-stroke-color);
}

.circle-big:hover {
  --stroke-opacity: 1;
  --animation-time: var(--hover-animation-time);
  box-shadow: 0px 0px 20px 0px var(--stroke-color);
}

.circle-animated {
  animation: rotate var(--animation-time) linear infinite;
}

@keyframes rotate {
  to {
    transform: rotate(var(--rotation));
  }
}
