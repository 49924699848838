/* Navbar */
nav {
  transition: all 0.9s ease;
  height: 80px;
  top: -80px;
}

@media screen and (max-width: 639px) {
  nav {
    height: auto;
  }
}

.stickyNav {
  position: sticky;
  top: 0;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.6);
  z-index: 999999;
  transition-delay: 0.4s;
}

/* Navbar Menu */

.link {
  cursor: pointer;
  position: relative;
  white-space: nowrap;
}

.link::before,
.link::after {
  position: absolute;
  width: 100%;
  height: 1px;
  background: currentColor;
  top: 100%;
  left: 0;
  pointer-events: none;
}

.link::before {
  content: '';
  /* show by default */
}

.link--elara::before {
  transform-origin: 50% 100%;
  transition:
    clip-path 0.5s,
    transform 0.5s cubic-bezier(0.2, 1, 0.8, 1);
  clip-path: polygon(
    0% 0%,
    0% 100%,
    0 100%,
    0 0,
    100% 0,
    100% 100%,
    0 100%,
    0 100%,
    100% 100%,
    100% 0%
  );
}

.link--elara:hover::before {
  transform: translate3d(0, 2px, 0) scale3d(1.08, 3, 1);
  clip-path: polygon(
    0% 0%,
    0% 100%,
    50% 100%,
    50% 0,
    50% 0,
    50% 100%,
    50% 100%,
    0 100%,
    100% 100%,
    100% 0%
  );
}

.link--elara {
  display: inline-block;
  transition: transform 0.5s cubic-bezier(0.2, 1, 0.8, 1);
}

.link--elara:hover span {
  transform: translate3d(0, -2px, 0);
}
