.sidebar {
  max-height: calc(100vh - 120px);
  overflow-y: auto;
}

/* Album Page */
.main-album {
  border-radius: 1rem;
  backdrop-filter: blur(5px);
  background: rgba(255, 255, 255, 0.1);
  overflow: hidden;
  box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5);
}

.pills {
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.5);
}

.not-found {
  height: 48vh;
}

.scrollToTopButton {
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.scrollToTopButton.visible {
  opacity: 1;
}
