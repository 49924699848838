@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import './components/components.css';
@import 'tailwindcss/utilities';

html {
  scroll-behavior: smooth;
}

/* Dark
https://colorhunt.co/palette/27374d526d829db2bfdde6ed

Light
https://colorhunt.co/palette/a1ccd1f4f2dee9b3847c9d96 */
