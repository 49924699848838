:root {
  --color: #a1ccd1;
}

.lightButtonHome {
  color: #27374d !important;
}

.buttonHome {
  padding: 1rem 2rem;
  border: 2px solid var(--color);
  border-radius: 4px;
  text-shadow: 0 0 15px var(--color);
  letter-spacing: 0.1rem;
  transition: 0.5s;
}

.buttonHome:hover {
  color: #27374d !important;
  border: 2px solid rgba(0, 0, 0, 0);
  box-shadow: 0 0 0px var(--color);
}

.buttonHome::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--color);
  z-index: -1;
  transform: scale(0);
  transition: 0.5s;
}

.buttonHome:hover::before {
  transform: scale(1);
  transition-delay: 0.5s;
  box-shadow:
    0 0 10px var(--color),
    0 0 30px var(--color),
    0 0 60px var(--color);
}

.buttonHome span {
  position: absolute;
  background: var(--color);
  pointer-events: none;
  border-radius: 2px;
  box-shadow:
    0 0 10px var(--color),
    0 0 20px var(--color),
    0 0 30px var(--color),
    0 0 50px var(--color),
    0 0 100px var(--color);
  transition: 0.5s ease-in-out;
  transition-delay: 0.25s;
}

.buttonHome:hover span {
  opacity: 0;
  transition-delay: 0s;
}

.buttonHome span:nth-child(1),
.buttonHome span:nth-child(3) {
  width: 40px;
  height: 4px;
}

.buttonHome:hover span:nth-child(1),
.buttonHome:hover span:nth-child(3) {
  transform: translateX(0);
}

.buttonHome span:nth-child(2),
.buttonHome span:nth-child(4) {
  width: 4px;
  height: 40px;
}

.buttonHome:hover span:nth-child(1),
.buttonHome:hover span:nth-child(3) {
  transform: translateY(0);
}

.buttonHome span:nth-child(1) {
  top: calc(50% - 2px);
  left: -50px;
  transform-origin: left;
}

.buttonHome:hover span:nth-child(1) {
  left: 50%;
}

.buttonHome span:nth-child(3) {
  top: calc(50% - 2px);
  right: -50px;
  transform-origin: right;
}

.buttonHome:hover span:nth-child(3) {
  right: 50%;
}

.buttonHome span:nth-child(2) {
  left: calc(50% - 2px);
  top: -50px;
  transform-origin: top;
}

.buttonHome:hover span:nth-child(2) {
  top: 50%;
}

.buttonHome span:nth-child(4) {
  left: calc(50% - 2px);
  bottom: -50px;
  transform-origin: bottom;
}

.buttonHome:hover span:nth-child(4) {
  bottom: 50%;
}

/* Button Load More */
.button-load-more {
  padding: 1em 2em;
  border: none;
  letter-spacing: 5px;
  transition: all 1500ms;
  outline-width: 2px;
  outline-style: solid;
}

.button-load-more:hover {
  color: #ffffff;
  transform: scale(1.1);
  box-shadow: 4px 5px 17px -4px #489fb5;
}

.button-load-more::before {
  content: '';
  position: absolute;
  left: -50px;
  top: 0;
  width: 0;
  height: 100%;
  background-color: #489fb5;
  transform: skewX(45deg);
  z-index: -1;
  transition: width 1500ms;
}

.button-load-more:hover::before {
  width: 250%;
}

.button-theme {
  box-shadow:
    6px 9px 10px -1px rgba(0, 0, 0, 0.15),
    1px 0px 8px 0px rgba(255, 255, 255, 1.1);
}
