/* CARD Main homage amd albums page */

.cardContainer::before {
  content: '';
  position: absolute;
  width: 140px;
  height: 140px;
  background-color: transparent;
  z-index: -1;
  border-radius: 50%;
  top: 10%;
  transition: all 1s;
}

.cardContainer:hover::before {
  transform: translate(140px, 340px);
  border-radius: 16% 70% 37% 43% / 23% 33% 54% 27%;
  background-color: rgb(157, 178, 191, 0.3);
  backdrop-filter: blur(5px);
  box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5);
}

.card {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  height: 35rem;
  border-radius: 1rem;
  backdrop-filter: blur(5px);
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5);
}

.card::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: 0.5s ease all;
}

.imageCard {
  transition: 0.5s ease all;
  width: 100%;
}

.card:hover .imageCard {
  transform: scale(1.1);
}

.contentText {
  margin-top: 340px;
}

/* CATEGORY CARD */
.categoryCard {
  transition: all 0.2s;
  cursor: pointer;
}

.card-inner {
  width: inherit;
  height: inherit;
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
  border-radius: 8px;
}

.categoryCard:hover {
  transform: scale(1.04) rotate(1deg);
}

.circleCard {
  width: 5.25rem;
  height: 5.25rem;
  border-radius: 35% 30% 75% 30% / 49% 30% 70% 51%;
  animation: move-up6 2s ease-in infinite alternate-reverse;
  background: repeating-linear-gradient(48deg, #27374d 0%, #7c9d96 100%);
}

.circleCard:nth-child(1) {
  top: -25px;
  left: -25px;
}

.circleCard:nth-child(2) {
  bottom: -25px;
  right: -25px;
  animation-name: move-down1;
}

@keyframes move-up6 {
  to {
    transform: translateY(-10px);
  }
}

@keyframes move-down1 {
  to {
    transform: translateY(10px);
  }
}

/* Card horizontal */
.cardHorShadowContain {
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
}

.cardHorShadowContain:nth-child(odd) {
  animation: 1s ease-out 0s 1 slideInRight;
}

.cardHorShadowContain:nth-child(even) {
  animation: 1s ease-out 0s 1 slideInLeft;
}

@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideInRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.cardHorShadow {
  box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5);
}

/* About Page */
.cardImage {
  height: 479px;
  width: 360px;
  transition: background 0.8s;
  box-shadow: 0 70px 63px -60px #000;
}

.cardImage h2 {
  opacity: 0;
  transition: all 0.5s ease;
}

.cardImage:hover h2 {
  opacity: 1;
}

.border-picture {
  height: 469px;
  width: 350px;
  background: transparent;
  transition: border 0.5s ease;
}

.border-picture-second {
  height: 460px;
  width: 340px;
  background: transparent;
  transition: border 0.5s ease;
}

.cardImage:hover .border-picture,
.cardImage:hover .border-picture-second {
  border: 1px solid #fff;
  transition: all 0.5s ease;
}

.profile-picture {
  transition: 0.5s ease-in-out;
  transform-origin: top left; /* add this in */
}

.cardImage:hover .profile-picture {
  transform: scale(2, 2);
}
