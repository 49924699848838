.waveContainer {
  position: absolute;
  top: 0;
  height: 420px;
  width: 100%;
  background-size: 400% 400%;
  animation: Background 10s ease infinite;
  @keyframes Background {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
}

@media screen and (max-width: 767px) {
  .waveContainer {
    display: none;
  }
}

/* General Wave */
.wave1,
.wave2,
.wave3,
.wave4,
.wave5 {
  border-radius: 50%;
}

/* Main Wave */
.wave {
  position: absolute;
  width: 500px;
  height: 500px;
  left: 50%;
  top: 0;
  transform: translate(-20%, 0);
  margin-left: -150px;
  margin-top: -150px;
}

/* Design my wave */
.wave > div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-width: 5px;
  border-style: solid;
  border-color: #d9e9f7;
}

/* Animate wave */
.wave1 {
  animation: animation_wave 2.5s linear infinite;
}

.wave2 {
  animation: animation_wave 3s linear infinite;
}

.wave3 {
  animation: animation_wave 3.5s linear infinite;
}

.wave4 {
  animation: animation_wave 4s linear infinite;
}

.wave5 {
  animation: animation_wave 4.5s linear infinite;
}

@keyframes animation_wave {
  0% {
    transform: scale(0);
    border-width: 10px;
  }
  40% {
    transform: scale(0.35);
    opacity: 0.95;
    border-width: 30px;
  }
  90% {
    transform: scale(0.85);
    opacity: 0.1;
    border-width: 60px;
  }
  100% {
    transform: scale(1);
    opacity: 0;
    border-width: 80px;
  }
}

.shadow1,
.shadow2,
.shadow3,
.shadow4,
.shadow5 {
  border-radius: 50%;
}

/* Main shadow */
.shadowWave {
  position: relative;
  width: 510px;
  height: 510px;
  left: 50%;
  top: 0;
  transform: translate(-20%, 0);
  margin-left: -155px;
  margin-top: -155px;
  z-index: 100;
}

/* Design my shadow */
.shadowWave > div {
  z-index: -3;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-width: 5px;
  border-style: solid;
  border-color: #609df0;
}

.shadow1 {
  animation: animation_wave 3s linear infinite;
}

.shadow2 {
  animation: animation_wave 3.5s linear infinite;
}

.shadow3 {
  animation: animation_wave 4s linear infinite;
}

.shadow4 {
  animation: animation_wave 4.5s linear infinite;
}

.shadow5 {
  animation: animation_wave 5s linear infinite;
}

@keyframes animation_wave {
  0% {
    transform: scale(0);
    opacity: 0.5;
    border-width: 15px;
  }
  40% {
    transform: scale(0.35);
    opacity: 0.5;
    border-width: 35px;
  }
  90% {
    transform: scale(0.85);
    opacity: 0.1;
    border-width: 65px;
  }
  100% {
    transform: scale(1);
    opacity: 0;
    border-width: 75px;
  }
}
