/* Loader Animation */
.loader {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  position: relative;
}

.loader::before {
  content: '';
  height: 100%;
  width: 100%;
  border: 1px solid black;
  border-radius: 50%;
  position: absolute;
  animation: doitreverse 4s infinite;
}

.loader::after {
  content: '';
  height: 100%;
  width: 100%;
  border: 1px solid black;
  border-radius: 50%;
  position: absolute;
  animation: doit 4s infinite;
}

@keyframes doit {
  0% {
    transform: scale(1);
  }

  50% {
    border-radius: 20% 50%;
    transform: scale(1.25);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes doitreverse {
  0% {
    transform: rotate(360deg);
  }

  50% {
    border-radius: 50% 20%;
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.eye {
  height: 50%;
  width: 50%;
  border-radius: 50%;
  border: 1px solid black;
  background-color: #489fb5;
  position: absolute;
  left: 25%;
  top: 25%;
  animation: showme 4s infinite;
}

.eye::before {
  content: '';
  height: 20%;
  width: 20%;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  top: 25%;
  left: 50%;
  animation: showme 4s infinite;
}

.eye::after {
  content: '';
  height: 10%;
  width: 10%;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  top: 60%;
  left: 50%;
  animation: showme 4s infinite;
}

@keyframes showme {
  0% {
    transform: scale(0.3);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.3);
  }
}
